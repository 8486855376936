import { Injectable } from '@angular/core';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  AccountClient,
  AccountResultDto,
  CompanyClient,
  SelectCompanyResultDto,
} from '../api-client';
import { claims } from '../constants/claims.constants';
import { unwrap } from '../extensions';
import { EncryptionService } from './encryption.service';
import { LocalStorageService } from './local-storage.service';
import { TrackingService } from '@core/services/tracking.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface UserInfo {
  id: number;
  email: string;
  roles: string[];
  companyId?: number;
  reviewerId?: number;
  isFirstLogin?: boolean;
  impersonatorId?: number;
}

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class JWTTokenService {
  get jwtToken(): string | null {
    return this.localStorage.get('access_token');
  }

  get decodedToken(): { [key: string]: string | string[] } {
    if (this.jwtToken) {
      return jwt_decode(this.jwtToken);
    } else {
      return {};
    }
  }

  get currentUser(): UserInfo | null {
    if (!this.jwtToken) {
      return null;
    }
    return {
      id: +this.decodedToken[claims.id],
      email: this.decodedToken[claims.email] as string,
      roles: this.decodedToken[claims.role] as string[],
      companyId: this.decodedToken.CompanyId ?
        +this.decodedToken.CompanyId :
        undefined,
      reviewerId: this.decodedToken.ReviewerId ?
        +this.decodedToken.ReviewerId :
        undefined,
      isFirstLogin: this.decodedToken.IsFirstLogIn === 'True',
      impersonatorId: this.decodedToken.ImpersonatedUserId ?
        +this.decodedToken.ImpersonatedUserId :
        undefined,
    };
  }

  private _currentUser: BehaviorSubject<UserInfo | null> = new BehaviorSubject<UserInfo | null>(
    null);
  currentUser$: Observable<UserInfo | null> = this._currentUser.asObservable();

  private _jwtToken: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    this.localStorage.get('access_token'));
  jwtToken$: Observable<string | null> = this._jwtToken.asObservable();

  constructor(
    private readonly localStorage: LocalStorageService,
    private encryptionService: EncryptionService,
    private readonly accountClient: AccountClient,
    private readonly companyClient: CompanyClient,
    private readonly tracking: TrackingService,
  ) {
    this._currentUser.next(this.currentUser);

    this.currentUser$.pipe(
      untilDestroyed(this),
    ).subscribe((user) => {
      if (user) {
        this.tracking.setUser(user);
      } else {
        this.tracking.setUser(null);
      }
    });
  }

  setToken(token: string) {
    if (token) {
      this.localStorage.set('access_token', token);
    }
  }

  refreshToken() {
    return this.accountClient.refreshToken({
      refreshToken: this.getRefreshToken(),
      userId: this.getUserId(),
    }).pipe(
      unwrap<AccountResultDto>(),
      tap((data) => this.setUserTokens(data)),
    );
  }

  selectCompany(companyId: number) {
    return this.companyClient.selectCompany(companyId).pipe(
      unwrap<SelectCompanyResultDto>(),
      tap((data) => this.setUserDetails(data)),
    );
  }

  setUserTokens({ accessToken, refreshToken }: AccountResultDto) {
    this.setRefreshToken(refreshToken!);
    this.setUserDetails({ accessToken: accessToken! });
  }

  setUserDetails({ accessToken }: { accessToken: string }) {
    this.setToken(accessToken!);
    this._jwtToken.next(this.jwtToken);
    this._currentUser.next(this.currentUser);
  }

  setRefreshToken(refreshToken: string) {
    if (refreshToken) {
      refreshToken = this.encryptionService.encrypt(refreshToken);
      this.localStorage.set('refresh_token', refreshToken);
    }
  }

  getRefreshToken(): string {
    let refreshToken = this.localStorage.get('refresh_token') ?? '';
    refreshToken = this.encryptionService.decrypt(refreshToken);
    return refreshToken;
  }

  getToken(): string | null {
    return this.localStorage.get('access_token');
  }

  isTokenAvailable(): boolean {
    // eslint-disable-next-line prefer-const
    let token = this.localStorage.get('access_token') ?? '';
    return (token !== '');
  }

  getUserId(): number {
    return +this.decodedToken[claims.id];
  }

  getEmailId(): string {
    return this.decodedToken[claims.email] as string;
  }

  getIsFirstLogIn(): boolean {
    return this.decodedToken.IsFirstLogIn === 'True';
  }

  getRoles(): string[] {
    const roles = this.decodedToken[claims.role];
    if (typeof roles === 'string') {
      return [roles];
    }
    return roles;
  }

  isUserInRole(role: string): boolean {
    return this.getRoles()?.includes(role);
  }

  getReviewerId() {
    return this.decodedToken.ReviewerId;
  }

  getCompanyId() {
    return this.decodedToken.CompanyId;
  }

  getExpiryTime() {
    return this.decodedToken.exp;
  }

  isTokenExpired(): boolean {
    const expiryTime: number = Number(this.getExpiryTime());
    if (expiryTime) {
      return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
    } else {
      return false;
    }
  }

  logout() {
    this.localStorage.clear();
    this._currentUser.next(null);
    this._jwtToken.next(null);
  }
}
