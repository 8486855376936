import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class AppTitleService {
  private readonly _title = new BehaviorSubject<string>('');
  title$ = this._title.asObservable();

  private readonly _titlePrefix = new BehaviorSubject<string>(
    environment.titlePrefix);
  titlePrefix$ = this._titlePrefix.asObservable();

  private readonly _titleSuffix = new BehaviorSubject<string>('');
  titleSuffix$ = this._titleSuffix.asObservable();

  private readonly _browserTitle = new BehaviorSubject<string | undefined>(
    undefined);
  browserTitle$ = this._browserTitle.asObservable();

  private readonly _notifications = new BehaviorSubject<number | undefined>(
    undefined);
  notifications$ = this._notifications.asObservable();

  constructor(
    private readonly titleService: Title,
    private readonly translateService: TranslateService,
  ) {
    combineLatest(
      [
        this.title$,
        this.titlePrefix$,
        this.titleSuffix$,
        this.browserTitle$,
        this.notifications$]).
      pipe(untilDestroyed(this)).
      subscribe(([title, prefix, suffix, browserTitle, notifications]) => {
        let titleValue = browserTitle ?? title;
        if (titleValue) {
          titleValue = this.translateService.instant(titleValue);
        }

        let titleToSet = 'Altorney';
        if (prefix && titleValue) {
          titleToSet = `${prefix} - ${titleValue}`;
        } else if (prefix) {
          titleToSet = prefix;
        } else if (titleValue) {
          titleToSet = titleValue;
        }

        if (suffix) {
          titleToSet = `${titleToSet} ${suffix}`;
        }

        if (notifications) {
          titleToSet = `(${notifications}) ${titleToSet}`;
        }

        this.titleService.setTitle(titleToSet);
      });
  }

  setTitle(title: string): void {
    this._title.next(title);
  }

  setTitlePrefix(titlePrefix: string): void {
    const prefix = titlePrefix ||
      (titlePrefix === undefined ? environment.titlePrefix : '');
    this._titlePrefix.next(prefix);
  }

  setBrowserTitle(browserTitle?: string): void {
    this._browserTitle.next(browserTitle);
  }

  setNotificationCount(count?: number): void {
    this._notifications.next(count);
  }

  set({ title, prefix, browserTitle }: {
    title: string,
    prefix: string,
    browserTitle?: string,
  }) {
    this.setTitle(title);
    this.setTitlePrefix(prefix);
    this.setBrowserTitle(browserTitle);
  }

  setSuffix(title: string): void {
    this._titleSuffix.next(title);
  }
}
