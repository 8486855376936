import { environment as env } from './environment.default';
import merge from 'lodash-es/merge';

export const environment = merge(env, {
  demo: true,
  environmentName: 'prod',
  ga: 'G-0RKZLZY4WC',
  // plausible: 'altorney.com',
  homeUrl: 'https://altorney.com',
  features: {},
  flatFileEnv: 'us_env_cNVyzWAq',
  flatFileKey: 'pk_52aafeeec0354319a2d58362d9b0492e',
  sentryDsn: 'https://c10fc6c8392818179e8aadb16162f676@o4507021723303936.ingest.us.sentry.io/4507491640934401',
}) as typeof env;
